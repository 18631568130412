<template>
  <div class="mt-3 font-17">
    <span v-for="course_lessons in lessons" :key="course_lessons.id">
      <b-row>
        <b-col class="align-items-center" lg="12">
          <div
            class="lesson-topic py-1"
            v-if="course_lessons.type === 'divide'"
          >
            <b class="mt-1 mx-2">{{ course_lessons.name }}</b>
          </div>
          <span v-if="course_lessons.type === 'lesson'" class="pointer">
            <!-- <div
              v-if="preTestCheck(course_lessons)"
              style="
                position: absolute;
                width: 95%;
                height: 100%;
                background-color: gray;
                opacity: 0.5;
              "
            >
              <div
                style="
                  position: block;
                  text-align: center;
                  margin: auto;
                  vertical-align: center;
                  height: 100%;
                "
                class="my-auto"
              >
                <div style="margin-top: 10px">
                  <span style="margin: auto"> </span>
                </div>
              </div>
            </div> -->
            <div
              @click.self="
                lessonDetail(course_lessons, course_lessons.subject_id)
              "
              :class="
                selected == course_lessons.id
                  ? 'd-flex justify-content-between align-items-center grey-bg'
                  : 'd-flex justify-content-between align-items-center lesson-bar'
              "
              :style="
                preTestCheck(course_lessons)
                  ? 'background: gray; opacity: 0.5; cursor: not-allowed;'
                  : ''
              "
            >
              <div class="my-1 mx-2">
                {{ course_lessons.name.includes("บทเรียน") ? "" : "บทเรียน " }}
                {{ course_lessons.name }}
              </div>

              <div class="d-flex align-items-center my-1 mx-2">
                <b-icon
                  v-if="checkLessonProgress(course_lessons.id)"
                  icon="eye"
                  class="close mx-2"
                  style="color: green"
                ></b-icon>
                <template v-if="course_lessons.pdf">
                  <button
                    @click="downloadFile(course_lessons.pdf.url)"
                    :disabled="preTestCheck(course_lessons)"
                    class="
                      btn btn-sm
                      main-orange-btn
                      btn-block
                      pill-btn
                      ml-2
                      mr-2
                    "
                    :style="
                      preTestCheck(course_lessons) ? 'white-space: nowrap; cursor: not-allowed;' : 'white-space: nowrap;'
                    "
                  >
                    Download เอกสาร
                  </button>
                </template>

                <div
                  @click="
                    lessonDetail(course_lessons, course_lessons.subject_id)
                  "
                  style="min-width: 120px"
                >
                  <i
                    :class="
                      selected == course_lessons.id
                        ? 'bi bi-pause-circle mr-2 font-25'
                        : 'bi bi-play-circle mr-2 font-25'
                    "
                  ></i>
                  <span class="my-auto">
                    {{ convertTominute(course_lessons.lesson_minute) }}
                  </span>
                </div>
              </div>
            </div>
          </span>
          <span v-if="course_lessons.type === 'quiz'" class="pointer">
            <div
              @click="goToQuiz(course_lessons.question_sets, answerCourse)"
              :class="
                selected == course_lessons.id
                  ? ' justify-content-between align-items-center grey-bg w-full'
                  : 'justify-content-between align-items-center lesson-bar w-full'
              "
              :style="
                'position: relative;' + course_lessons.question_sets
                  ? !inTimeDurationWithMinute(
                      course_lessons.question_sets.test_start_at,
                      course_lessons.question_sets.time_limit_minute
                    ) || postTestCheck(course_lessons.question_sets)
                    ? 'background: gray; opacity: 0.5; cursor: not-allowed;'
                    : ''
                  : ''
              "
            >
              <!-- v-if="
                  course_lessons.question_sets
                    ? !inTimeDurationWithMinute(
                        course_lessons.question_sets.test_start_at,
                        course_lessons.question_sets.time_limit_minute
                      ) || postTestCheck(course_lessons.question_sets)
                    : false
                " -->
              <!-- <div
                v-if="
                  course_lessons.question_sets
                    ? !inTimeDurationWithMinute(
                        course_lessons.question_sets.test_start_at,
                        course_lessons.question_sets.time_limit_minute
                      ) || postTestCheck(course_lessons.question_sets)
                    : false
                "
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: gray;
                  opacity: 0.5;
                "
              >
                <div
                  style="
                    position: block;
                    text-align: center;
                    margin: auto;
                    vertical-align: center;
                    height: 100%;
                  "
                  class="my-auto"
                >
                  <div style="margin-top: 10px">
                    <span style="margin: auto">
                      {{
                        course_lessons.question_sets
                          ? testInTimeText(
                              course_lessons.question_sets.test_start_at,
                              course_lessons.question_sets.time_limit_minute
                            )
                          : ""
                      }}
                    </span>
                  </div>
                </div>
              </div> -->
              <div class="row w-full">
                <div class="col-6 col-md-8 my-auto">
                  <div class="my-1 mx-2">
                    {{
                      course_lessons.question_sets.test_type == "pretest"
                        ? "(Pre Test)"
                        : course_lessons.question_sets.test_type == "posttest"
                        ? "(Post Test)"
                        : ""
                    }}
                    {{ course_lessons.name }}
                  </div>
                </div>
                <div class="col-6 col-md-2 my-auto">
                  <div class="d-flex my-1 mx-2">
                    <!-- <i
                  class="mx-3 fa fa-check-circle my-auto"
                  :style="`color: ${
                    answerCourse
                      ? getAnswerOfLesson(course_lessons, answerCourse)
                        ? 'green'
                        : 'gray'
                      : 'gray'
                  };`"
                ></i> -->
                    <button
                      :disabled="
                        !inTimeDuration(
                          course.lesson_start_at,
                          course.lesson_end_at
                        ) || postTestCheck(course_lessons.question_sets)
                      "
                      class="btn btn-outline-light text-dark"
                      :style="'min-width: 150px;'"
                    >
                      <i class="bi bi-pencil"></i> แบบทดสอบ
                    </button>
                  </div>
                </div>
                <div class="col-12 col-md-2 my-auto">
                  <div class="d-flex my-auto mx-2">
                    <div v-if="getAnswerOfLesson(course_lessons, answerCourse)">
                      <div
                        v-if="
                          getAnswerOfLesson(course_lessons, answerCourse)
                            .is_checking
                        "
                        :style="`color:  ${
                          course_lessons.question_sets
                            ? getAnswerOfLesson(course_lessons, answerCourse)
                                .user_score >=
                              getMaxQuestion(course_lessons, answerCourse)
                              ? 'green'
                              : 'red'
                            : 'gray'
                        };`"
                      >
                        คะแนนสอบ :
                        {{
                          getAnswerOfLesson(course_lessons, answerCourse)
                            .user_score
                        }}
                        /
                        {{ getMaxQuestion(course_lessons, answerCourse) }}
                      </div>
                      <div v-else>กำลังตรวจ..</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <br />
        </b-col>
      </b-row>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    lessons: null,
    selected: null,
    courseStatus: null,
    answerCourse: null,
    lessonProgress: null,
  },
  computed: {
    ...mapState({
      userData: (state) => state.Auth.userData,
      course: (state) => {
        return state.Courses.course;
      },
    }),
  },
  methods: {
    checkLessonProgress(lessonId) {
      if (!this.lessonProgress) {
        return false;
      }

      const lesson = this.lessonProgress.find(
        (lesson) => lesson.lesson_id == lessonId
      );

      if (!lesson) {
        return false;
      }

      return lesson.status == 1;
    },
    testInTimeText(startTimeString, duration) {
      if (!startTimeString) {
        return null;
      }

      const startTime = moment(startTimeString);
      const endTime = moment(startTimeString).add(duration, "minutes");
      const now = moment();

      if (now >= startTime && now <= endTime) {
        return null;
      } else {
        if (now < startTime) {
          return `เริ่มเวลาสอบ ${startTime.format(`DD/MM/Y HH:mm:ss`)}`;
        } else if (now > endTime) {
          return `หมดเวลาสอบ`;
        } else {
          return `เริ่มเวลาสอบ ${startTime.format(`DD/MM/Y HH:mm:ss`)}`;
        }
      }
    },
    inTimeDurationWithMinute(startTimeString, duration) {
      if (!startTimeString) {
        return true;
      }
      const startTime = moment(startTimeString);
      const endTime = moment(startTimeString).add(duration, "minutes");
      const now = moment();

      return now >= startTime && now <= endTime;
    },
    inTimeDuration(startTimeString, endTimeString) {
      if (!startTimeString) {
        return true;
      }
      const startTime = moment(startTimeString);
      const endTime = moment(endTimeString);
      const now = moment();

      return now >= startTime && now <= endTime;
    },

    getAnswerOfLesson(lesson, answer) {
      if (answer) {
        return answer.result.find((answer) => answer.lession_id == lesson.id);
      } else {
        return null;
      }
    },

    getMaxQuestion(lesson, answer) {
      let maxScroll = 0;
      if (answer) {
        const answerSet = answer.result.find(
          (answer) => answer.lession_id == lesson.id
        );
        maxScroll =
          answerSet.question_set.max_show_question ?? answerSet.max_score;
        return maxScroll;
      } else {
        return maxScroll;
      }
    },
    async lessonDetail(lesson, courseId) {
      if (!this.courseStatus) {
        this.goToSubScribe();
        return;
      }

      if (this.preTestCheck(lesson)) {
        return;
      }
      let path = `/lessons`;
      await this.$router.push({
        path: path,
        query: { courseId: courseId, lessonId: lesson.id },
      });
    },
    downloadFile(link) {
      if (!this.courseStatus) {
        this.goToSubScribe();
        return;
      }

      window.open(link, "_blank");
    },
    goToSubScribe() {
      if (!this.userData.id) {
        this.$router.push({
          path: "/login",
        });
      } else {
        alert("เนื้อหาส่วนนี้ สำหรับผู้ที่ลงทะเบียนเรียนเท่านั้น");
      }
    },
    // eslint-disable-next-line no-unused-vars
    async goToQuiz(quizId, answerQuestion) {
      if (
        !this.inTimeDuration(
          this.course.lesson_start_at,
          this.course.lesson_end_at
        )
      ) {
        return;
      }

      if (
        !this.inTimeDurationWithMinute(
          quizId.test_start_at,
          quizId.time_limit_minute
        )
      ) {
        return;
      }
      if (!this.courseStatus) {
        this.goToSubScribe();
        return;
      }

      if (this.postTestCheck(quizId)) {
        return;
      }

      // const latestAnswer = answerQuestion.result.find(
      //   (answer) => answer.lession_id == quizId.lesson_id
      // );

      // check answer pass will not test again
      // if (latestAnswer) {
      //   if (latestAnswer.is_checking) {
      //     if (latestAnswer.user_score >= quizId.score_for_pass) {
      //       return;
      //     }
      //   }
      // }

      // if (!quizId.repeatable) {
      //   if (answerQuestion) {
      //     if (latestAnswer) {
      //       if (latestAnswer.attemp_amount >= quizId.repeat_number) {
      //         return;
      //       }
      //     }
      //   }
      // }

      let path = `/quiz`;
      await this.$router.push({
        path: path,
        query: { id: quizId.id, enrollId: this.courseStatus.enroll_id },
      });
    },
    convertTominute(minute) {
      if (minute) {
        return `${minute} นาที`;
      } else {
        return "";
      }
    },
    postTestCheck(questionSet) {
      if (!questionSet) {
        return false;
      }

      if (questionSet.test_type != "posttest") {
        return false;
      }

      if (this.lessonProgress) {
        let lessonProgress = this.lessonProgress.find(
          (lesson) => lesson.lesson_id == questionSet.lesson_related
        );

        if (!lessonProgress) {
          return false;
        }

        if (lessonProgress.status != 1) {
          return true;
        }

        return false;
      }
      return false;
    },
    preTestCheck(lesson) {
      const questionLesson = this.lessons.filter((l) => l.type == "quiz");

      const questionSet = questionLesson.map((l) => l.question_sets);

      const questionPreMatchLesson = questionSet.find(
        (questionSet) =>
          questionSet.lesson_related == lesson.id &&
          questionSet.test_type == "pretest"
      );

      if (!questionPreMatchLesson) {
        return false;
      }

      if (this.answerCourse) {
        const answer = this.answerCourse.result.find(
          (answer) => answer.lession_id == questionPreMatchLesson.lesson_id
        );

        if (!answer) {
          return true;
        }

        return false;
      }
      return false;
    },
  },
};
</script>

<style></style>
